<template>
  <div>
    <van-nav-bar
      title="我的承诺书"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="check">
      <div class="signature2">
        <div class="signature2-title">
          <div class="ctx-title">
            <div class="title-ctx">我承诺</div>
            <div class="title-fk"></div>
          </div>
        </div>
        <div class="chengnuo">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
            alt=""
          />
          <div>每天用一点时间来探索和理解自己的情绪</div>
        </div>

        <div class="chengnuo">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
            alt=""
          />
          <div>没有时间练习的时候，我也会照顾好自己，和情绪好好相处</div>
        </div>

        <div class="chengnuo">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
            alt=""
          />
          <div>用心地体会和了解我的情绪，对它多一点耐心</div>
        </div>

        <div class="chengnuo">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
            alt=""
          />
          <div>{{ words }}</div>
        </div>
        <img class="qianshuimg" :src="sgin_url" alt="" />
      </div>
      <div class="gohome">
        <button @click="gohome">返回今日练习</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getPromise } from "../../api/login";

export default {
  data() {
    return {
      sgin_url: "",
      words: "",
    };
  },
  created() {
    this.getgetPromise();
  },
  methods: {
    getgetPromise() {
      let data = {
        userId: window.localStorage.getItem("userId"),
      };
      getPromise(data).then((res) => {
        this.sgin_url = res.data.sgin_url;
        this.words = res.data.words;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    gohome() {
      this.$router.push("/setout/index");
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.check {
  background-color: #fff;
  min-height: 100vh;
  padding: 0 40px;
}
.signature2 {
  background-image: url("https://lesson.iapeap.com/images/EmotionalValue/xinfeng.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  height: 550px;
  padding: 60px 40px 0px 40px;
}
.ctx-title {
  .title-ctx {
    z-index: 999;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #000025;
    line-height: 25px;
  }
  .title-fk {
    width: 35px;
    height: 5px;
    background: #629ee9;
    position: relative;
    bottom: 8px;
    z-index: 998;
    transform: skew(-25deg);
  }
}
.chengnuo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  img {
    width: 12px;
  }
  input {
    width: 100%;
    border-radius: 10px;
    height: 24px;
    border: 1px solid #e0e0e0;
    color: #e0e0e0;
    padding: 0 20px;
  }
}
.qianshu {
  width: 155px;
  height: 58px;
  border: 1px solid #e0e0e0;
  color: #e0e0e0;
  font-size: 12px;
  border-radius: 10px;
  float: right;
  margin-top: 50px;
  padding: 10px;
}

.signature2-title {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.qianshuimg {
  margin-top: 60px;
  width: 80px;
  height: 50px;
  float: right;
}
.gohome {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    background-color: #629ee9;
    border: 0;
    height: 36px;
    width: 156px;
    color: #fff;
    border-radius: 25px;
    box-shadow: 0px 8px 8px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>
