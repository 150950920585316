import { service } from "../http/index";

// 登录
export function login(data) {
  return service({
    method: "get",
    url: "/userLogin",
    params: data,
  });
}

// 个人信息
export function getUserInfo(data) {
  return service({
    method: "get",
    url: "/getUserInfo",
    params: data,
  });
}

//练习记录

export function Practice(data) {
  return service({
    method: "get",
    url: "/getPractice",
    params: data,
  });
}

//练习成果

export function practiceResult(data) {
  return service({
    method: "get",
    url: "/practiceResult",
    params: data,
  });
}

//喜欢的练习

export function getMyFavourite(data) {
  return service({
    method: "get",
    url: "/getMyFavourite",
    params: data,
  });
}

//首页
export function homePage(data) {
  return service({
    method: "get",
    url: "/homePage",
    params: data,
  });
}

// 圈子列表

export function getCircleList(data) {
  return service({
    method: "get",
    url: "/getCircleList",
    params: data,
  });
}

//上传文件

export function uploadFile(data) {
  const formData = new FormData();
  formData.append("file", data); // Assuming 'data' is the file you want to upload

  return service({
    method: "post",
    url: "/uploadFile",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//修改用户信息
export function userUpdate(data) {
  return service({
    method: "post",
    url: "/userUpdate",
    params: data,
  });
}

//获取首页详情

export function getTodayPractice(data) {
  return service({
    method: "post",
    url: "/getTodayPractice",
    params: data,
  });
}
// 练习列表
export function getPracticeList(data) {
  return service({
    method: "get",
    url: "/getPracticeList",
    params: data,
  });
}

// 点击完成
export function setComplated(data) {
  return service({
    method: "get",
    url: "/setComplated",
    params: data,
  });
}

// 情绪小练习保存
export function saveEmoExercise(data) {
  return service({
    method: "post",
    url: "/saveEmoExercise",
    data,
  });
}

// 情绪小练习回显
export function getEmoExercise(data) {
  return service({
    method: "get",
    url: "/getEmoExercise",
    params: data,
  });
}

// 情绪日记
export function saveEmoDiary(data) {
  return service({
    method: "post",
    url: "/saveEmoDiary",
    data,
  });
}

//查看情绪日记
export function getEmoDiary(data) {
  return service({
    method: "get",
    url: "/getEmoDiary",
    params: data,
  });
}

//圈子评论
export function saveCircle(data) {
  return service({
    method: "get",
    url: "/saveCircle",
    params: data,
  });
}

//开始测评
export function startAssess(data) {
  return service({
    method: "get",
    url: "/startAssess",
    params: data,
  });
}

//测评结果保存
export function assessSave(data) {
  return service({
    method: "post",
    url: "/assessSave",
    data,
  });
}

//查询报告

export function result(data) {
  return service({
    method: "post",
    url: "/result",
    params: data,
  });
}

//填写承诺书
export function setPromise(data) {
  return service({
    method: "post",
    url: "/setPromise",
    data,
  });
}

//情景训练场
export function getScene(data) {
  return service({
    method: "get",
    url: "/getScene",
    params: data,
  });
}

//查看承诺书

export function getPromise(data) {
  return service({
    method: "get",
    url: "/getPromise",
    params: data,
  });
}

//点击收藏

export function setFavourite(data) {
  return service({
    method: "get",
    url: "/setFavourite",
    params: data,
  });
}
//取消收藏

export function cancelFavourite(data) {
  return service({
    method: "get",
    url: "/cancelFavourite",
    params: data,
  });
}

//测评记录

export function record(data) {
  return service({
    method: "get",
    url: "/record",
    params: data,
  });
}

//是否全部完成

export function getStageIsComplated(data) {
  return service({
    method: "get",
    url: "/getStageIsComplated",
    params: data,
  });
}

//勋章列表

export function getMedalList(data) {
  return service({
    method: "get",
    url: "/getMedalList",
    params: data,
  });
}

//勋章保存

export function saveMedal(data) {
  return service({
    method: "post",
    url: "/saveMedal",
    data,
  });
}

//情绪日记点赞

export function likeEmoDiary(data) {
  return service({
    method: "get",
    url: "/likeEmoDiary",
    params: data,
  });
}

//情绪日记点赞

export function cancelLikeEmoDiary(data) {
  return service({
    method: "get",
    url: "/cancelLikeEmoDiary",
    params: data,
  });
}

//查看7天坐标

export function getEmoPoint(data) {
  return service({
    method: "get",
    url: "/getEmoPoint",
    params: data,
  });
}

// 情景训练场选项保存

export function saveScene(data) {
  return service({
    method: "get",
    url: "/saveScene",
    params: data,
  });
}

// 修改密码
export function updatePassWord(data) {
  return service({
    method: "get",
    url: "/updatePassWord",
    params: data,
  });
}

// 修改密码
export function userRegister(data) {
  return service({
    method: "get",
    url: "/userRegister",
    params: data,
  });
}

// 查看勋章
export function getMedalDetail(data) {
  return service({
    method: "get",
    url: "/getMedalDetail",
    params: data,
  });
}

// 页面停留时间
export function memberLog(data) {
  return service({
    method: "get",
    url: "/memberLog",
    params: data,
  });
}

// 查看对比报告
export function contrastResult(data) {
  return service({
    method: "get",
    url: "/contrastResult",
    params: data,
  });
}
